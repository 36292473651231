// Here you can add other styles

//  .tablecollapsoff {
//     $table-cell-padding-y:        .5rem ;
//     $table-cell-padding-x:        .5rem ;
//     border-collapse: collapse;
//     color: red;
//     border-radius: 10;
//   }

//    .tablecollapson {
//     border-collapse: unset;
//     color: black;
//     $table-cell-padding-y:        1.0rem ;
//     $table-cell-padding-x:        1.0rem ;
//     border-radius: 15;
//   }
.table {
  $table-cell-padding-y: 1rem;
  $table-cell-padding-x: 1rem;
}
.header-nav .nav-link {
  color: white;
}
@media screen and (min-width: 601px) {
  .navDrpList {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .navDrpList {
    font-size: 10px;
  }
}
.customDrp {
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: rgb(177 183 193);
}

.itemPerPageClass {
  position: absolute;
  right: 1%;
}

.lightGrayPlaceholder::placeholder {
  color: lightgray;
}

.drpInvalid {
  border-color: #e55353;
  //background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e55353%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e55353%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  //text-align: right;
}

.tableBodyRowClass :hover {
  //background-color: #dadbde;
  background-color: #f3f3f3;
}

.tableBodyRowDarkClass :hover {
  background-color: #34353e;
}

// .tableDataCellClass {
//   vertical-align: 'middle';
//   overflow: 'hidden';
//   cursor: 'pointer';
// }

// .tableDataCellBodyClass {
//   padding-left: 15px !important;
// }

.tableHeaderCursorPointerClass {
  cursor: pointer;
}

/////--table
///
.tableFixHead {
  table-layout: fixed;
  border-collapse: collapse;
}
.tableFixHead .tbodyC {
  display: block;
  overflow: auto;
  height: 300px;
}
.trC {
  width: 100% !important;
}
.tableFixHead .theadC {
  display: block;
}
.tableFixHead .thC,
.tableFixHead .tdC {
  padding: 5px 10px;
}

.con-div {
  overflow: auto;
  height: 300px;
  width: 100%;
  padding-left: 50px;
}

.tableS .thS.tdS {
  outline: 1px solid #ccc;
  padding: 0.25em 0.5em 0.25em 1em;
  text-indent: -0.5em;
}

.thS {
  top: 0;
  position: sticky;
  background-color: #666;
}
// .divc {
//   padding: 2%;
//   height: 300px;
//   overflow-y: scroll;
//  }

//  .tablec {
//   text-align: center;
//   position: relative;
//   border-collapse: separated;
//   width: 100%;
//  }
//  .thc {
//   top: 0;
//   position: sticky;
//   background-color: aqua;
//   color: white;
//  }

div.panel,
p.flip {
  margin: 0px;
  padding: 5px;
  text-align: center;
  background: #888a8a;
  border: solid 1px #fff;
}

div.panel {
  width: 100%;
  height: 100px;
  display: none;
  background: #dffdc1;
}

.divFilterClass {
  border: solid;
  border-color: #c9cacc;
  border-width: 1px;
  border-radius: 6px;
}

.FilterHeader {
  border-left: solid;
  border-right: solid;
  border-color: #c9cacc;
  border-width: 1px;
}

.removeBorder {
  border-style: none !important;
  width: 0 !important;
}

// .wrapperNew {
//   overflow: hidden;
//   //margin-top: 28px !important;
// }

#reveal-content {
  max-height: 1000px;
  transition: all 2s ease-in;
}

// .STableTh {
//   border: none !important;
// }

.hide {
  max-height: 0 !important;
  transition: all 0.2s ease-out;
}
.STablecancel {
  margin-left: 50px;
}
.STablesearch {
  background: #00b7ff;
  color: white;
}

.ActiveButton {
  color: #06a636;
}
.InActiveButton {
  color: #ca0101;
}
// -----------------TB

.CustomTableTh {
  position: -webkit-sticky;
  position: sticky;
  top: 69px;
  z-index: 2;
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  //text-indent: -0.5em;
  padding: 0.5rem;
  font-weight: 600;
  text-align: -webkit-match-parent;
  vertical-align: middle;
  overflow: hidden;
  border: none !important;
}

.CustomTableTd {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  //text-indent: -0.5em;
  padding: 0.5rem;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
  padding: 0.9rem;
  border-color: #d8dbe0;
  padding-left: 13px !important;
}

.ActionCustomTableTd {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  //text-indent: -0.5em;
  padding: 0.5rem;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
  padding: 0.9rem;
  border-color: #d8dbe0;
  padding-left: 3px !important;
}

.ActionDarkCustomTableTd {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  //text-indent: -0.5em;
  padding: 0.5rem;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
  padding: 0.9rem;
  border-color: #000000;
  padding-left: 3px !important;
}

.DarkCustomTableTd {
  padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  //text-indent: -0.5em;
  padding: 0.5rem;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
  padding: 0.9rem;
  border-color: #000000;
  padding-left: 13px !important;
}

//------------------text box
// .formInputBlue {
//   position: relative;
//   //font-family: "Source Sans Pro", sans-serif;
//   //font-weight: 600;
//   //width: 100%;
//   //height: 40px;
//   border: none;
//   padding: 0 10px;
//   box-shadow: none;
//   outline: none;
//   -webkit-transition: all .5s ease;
//      -moz-transition: all .5s ease;
//       -ms-transition: all .5s ease;
//        -o-transition: all .5s ease;
//           transition: all .5s ease;
// }
.formInputBlue::placeholder {
  color: lightgray;
  //color: red;
}
.formInputBlue.smooth-corner {
  border-radius: 7px;
}
.inValidFormInput {
  border-color: #ca0101;
}
.ccInputDisableColoe {
  background-color: rgb(59, 60, 72);
  border-color: rgb(59, 60, 72);
}
.formInputBlue.basic {
  border: 1.5px solid rgb(177 183 193);
  //border: 1.5px solid red;
}

.formInputBlueDark.basic {
  border: 1.5px solid rgb(55 56 65);
  //border: 1.5px solid red;
}

.formInputBlueDark::placeholder {
  //color: lightgray;
  color: grey;
}

.formInputBlue.basic:hover {
  border-color: rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.formInputBlue.basic:focus {
  border-color: #00b7ff;
  box-shadow: 0 0 10px rgba(66, 133, 244, 0.5);
}

.formBodyDark {
  background-color: #3a3b44;
  border-color: rgba(255, 255, 255, 0.075);
}

.formBodyLigth {
  background-color: #f9fafa;
}

.formButtonLigth {
  background: #00b7ff;
  color: white;
}

.formButtonLigth:hover {
  background: #3fc9ff;
  color: white;
}

.formButtonDark {
  background-color: #4f5d73;
  color: white;
}

.formButtonDark:disabled,
.formButtonDark[disabled] {
  background-color: #42434c;
  color: #828282;
}

.formButtonLigth:disabled,
.formButtonLigth[disabled] {
  background-color: #72cef3;
  color: white;
}

// .formButtonLigthDisable{
//   background-color: #72cef3;
//   color: white;
// }

.formButtonDark:hover {
  background-color: #697588;
  color: white;
}

.formInputBlueDark.basic:hover {
  border-color: rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.formInputBlueDark.basic:focus {
  border-color: #00b7ff;
  box-shadow: 0 0 10px rgba(66, 133, 244, 0.5);
}

.Fmt2 {
  margin-top: 28px;
}

.TabViewColorLigth {
  color: rgb(132 132 132);
}

.TabViewColorDark {
  color: #bfbfbf;
}

.paginationBackgroundColorLigth {
  background-color: #ecebeb !important;
  color: black !important;
  border-color: #c4c9d0 !important;
}

.paginationBackgroundColorDark {
  background-color: #2a2b36 !important;
  border-color: #57585f !important;
}

.float-right {
  float: right;
}

// Learn about this technique:
// @link https://moderncss.dev/pure-css-custom-checkbox-style/

:root {
  --form-control-color: #00b7ff;
  --form-control-disabled: #959495;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

// .form-control--disabled {
//   color: var(--form-control-disabled);
//   cursor: not-allowed;
// }

.CheckBoxClassDark {
  border-color: #616161 !important;
}

.CheckBoxClass {
  // /* Add if not using autoprefixer */
  // -webkit-appearance: none;
  // /* Remove most all native input styles */
  // appearance: none;
  // /* For iOS < 15 */
  // background-color: var(--form-background);
  // /* Not removed via appearance */
  // margin: 0;

  // font: inherit;
  // color: currentColor;
  // width: 1.15em;
  // height: 1.15em;
  // border: 0.15em solid currentColor;
  // border-radius: 0.15em;
  // transform: translateY(-0.075em);

  // display: grid;
  // place-content: center;
  //-webkit-appearance: none;
  //-moz-appearance: none;
  // appearance: none;
  // margin-top: 8px;
  // background-color: var(--form-background);
  // margin: 0;
  // font: inherit;
  // color: currentColor;
  // width: 2.85em;
  // height: 2.85em;
  // border: solid;
  // border-radius: 0.55em;
  // transform: translateY(-0.075em);
  // display: grid;
  // place-content: center;
  // border-width: 1.5px;
  // border-color: #b1b7c1;

  appearance: none;
  margin-top: 8px;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.85em;
  height: 1.85em;
  border: solid;
  border-radius: 0.35em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  border-width: 1.5px;
  border-color: #b1b7c1;
}

.CheckBoxClass::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.CheckBoxClass:checked::before {
  transform: scale(1);
}

// input[type="checkbox"]:focus {
//   outline: max(2px, 0.15em) solid currentColor;
//   outline-offset: max(2px, 0.15em);
// }

.CheckBoxClass:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.row > * {
  padding-right: calc(var(--cui-gutter-x) * 0.3) !important;
  padding-left: calc(var(--cui-gutter-x) * 0.3) !important;
}

//------Tree
.tree {
  --spacing: 1.5rem;
  --radius: 10px;
}

.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.tree ul li {
  border-left: 2px solid #ddd;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

//.tree li::after,
.tree summary::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
}

.tree summary::before {
  // content     : '+';
  // z-index     : 1;
  // background  : #696;
  // color       : #fff;
  // line-height : calc(2 * var(--radius) - 2px);
  // text-align  : center;

  content: '+';
  z-index: 1;
  background: #ecebeb;
  color: #000;
  line-height: calc(2 * var(--radius) - 2px);
  text-align: center;
}

.tree details[open] > summary::before {
  content: '−';
}

.spanFrontHeigth {
  font-size: 17px !important;
}

.box {
  border: 2px solid #ecebeb;
}
.box h5 {
  background: #ecebeb;
  color: #5b504c;
  padding: 10px;
}
.box p {
  // background: #ecebeb;
  // color: #5b504c;
  // padding: 10px;
  // font-size: 19px;
  // font-weight: 500;

  background: #f7f7f7;
  color: #5b504c;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}
.box {
  -moz-border-radius-topright: 5px;
  -moz-border-radius-topleft: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-top-left-radius: 5px;
}

.drpDisabledOptionLigth {
  background-color: #ecebeb;
  color: #868585;
}

.PermissionsFeaturesLigthMode {
  border: var(--cui-accordion-border-width) solid #dadada !important;
}

.PermissionsFeaturesDarkMode {
  border: var(--cui-accordion-border-width) solid #52535b !important;
}

.PermissionsFeaturesLigthMode button {
  color: Black !important;
}

.PermissionsFeaturesDarkMode button {
  color: white !important;
  background: #44454e !important;
}

.PermissionsFeaturesLigthMode button::after {
  color: red !important;
}

.selected .calendar-cell-inner {
  background-color: #00b7ff !important;
}

.range .calendar-cell-inner {
  background-color: #71cff3 !important;
}

.btn-ghost-primary {
  background-color: #ffffff !important;
  color: #374253 !important;
  box-shadow: 0 1px 1px 0
      rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.14),
    0 2px 1px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.12),
    0 1px 3px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.2) !important;
}

// .datePickerInputDarkMode input{
//   background-color: #4d4e56 !important;
// }

// .datePickerInputLigthMode input{
//   background-color: #ffffff !important;
// }

// [type="date"] {
//   background:#fff;
//   //url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  90% 50% no-repeat ;
// }
// [type="date"]::-webkit-inner-spin-button {
//   display: none;
// }
// [type="date"]::-webkit-calendar-picker-indicator {
//   opacity: 0;
// }

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.headlineBox {
  border: solid;
  border-width: 1px;
  border-radius: 8px;
  padding: 5px;
}

.centerHeadline {
  & .headlineContext {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
  }
}

